<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-account-edit-outline
      </v-icon>
      Edition d'un compte vendeur :
      <span v-if="seller">
        {{ seller.first_name }}
      </span>
    </template>
    
    <template v-slot:toolbar-buttons>
      <ButtonAction
        :action="requestPasswordRecovery"
        icon="mdi-send-lock-outline"
        text="Envoi reset du mot de passe"
      />
    </template>
    
    <template v-slot:content>
      <v-card
        color="transparent"
        flat
      >
        <v-overlay
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <v-card>
                <v-card-title>Détails</v-card-title>
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                  >
                    <v-row dense>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="seller.first_name"
                          :label="$t('views.sellers.forms.labels.first_name')"
                          :rules="[rules.required]"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="seller.last_name"
                          :label="$t('views.sellers.forms.labels.last_name')"
                          :rules="[rules.required]"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="seller.email"
                          :label="$t('views.sellers.forms.labels.email')"
                          :rules="[rules.required]"
                          prepend-inner-icon="mdi-email-outline"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="seller.phone"
                          :label="$t('views.sellers.forms.labels.phone')"
                          prepend-inner-icon="mdi-phone-outline"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        sm="6"
                        xs="12"
                      >
                        <v-switch
                          v-model="seller.enabled"
                          :label="$t('views.sellers.forms.labels.enabled')"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="save"
                  >
                    {{ $t('forms.buttons.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <Snackbar />
    </template>
  </Page>
</template>

<script>
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import EventBus from '@/plugins/event-bus'
import Page from '@/components/core/Page.vue'
import Snackbar from '@/components/core/Snackbar'

export default {
  name: 'SellersEdit',
  components: { ButtonAction, Page, Snackbar },
  data() {
    return {
      request: null,
      valid: false,
      loading: false,
      mounted: false,
      seller: {},
      rules: {
        required: value => !!value || this.$i18n.t('forms.fields.required'),
      },
    }
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    cancel() {
      this.message = { type: null, text: null }
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load(id) {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.loading = true
      this.$axios.get('sellers/' + id, { cancelToken: axiosSource.token })
        .then(response => {
          this.loading = false
          this.seller = response.data
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
        })
    },
    requestPasswordRecovery() {
      this.loading = true
      this.$axios.post('/sellers/passwords/recoveries', {
        email: this.seller.email,
      })
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      this.$refs.form.validate()
      if (!this.valid) return false

      this.loading = true
      this.$axios.put('sellers/' + this.$route.params.id, this.seller)
        .then(response => {
          this.loading = false
          this.seller = response.data
          this.snackbar(
            'green',
            this.$i18n.t('views.sellers.forms.messages.save_succeed', { sellerName : this.seller.first_name })
          )
        })
        .catch(error => {
          let error_message =  error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.loading = false
          this.snackbar(
            'error',
            this.$i18n.t('views.sellers.forms.messages.save_failed', { error : error_message })
          )
        })
    },
    snackbar(color, message) {
      EventBus.$emit('SNACKBAR', { color : color, message: message })
    },
  },
}
</script>

<style scoped>
</style>
